import React from 'react'

const NavigationDots = ({ active }) => {
  return (
    <div className='app__navigation'>
        {['about', 'experiences', 'work','contact'].map((item, index)=> (
            <a 
            href={`#${item}`} 
            key= {item + index}
            className='app__navigation-dot'
            style={ active===item ? { backgroundColor: `var(--secondary-color)`}: {}}
            >
            </a>
        ))}
    </div>
  )
}

export default NavigationDots